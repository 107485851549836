<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs">
      <template #title>הסבות לחברות</template>

      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>

<script>
import ReportService from "@/services/ReportService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import TransferCompanyEntity from "@/views/TransferCompanyEntity.vue";
import ReportEntity from "@/views/ReportEntity.vue";
import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import { saveAs } from "file-saver";

export default {
  name: "TransfersCompanies",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    profileId() {
      return +this.$store.state.auth.profileId;
    },
  },
  created() {
    const reportsFields = FieldService.list("reports");
    const reportsfilters = FilterService.list();
    console.log('asdasd');
    const sideViewEntity = {
      component: TransferCompanyEntity,
      key: "TransferBatchItemId",
      recordUrl: "transfers",
      preview: true,
      readOnly: true,
      title: "פרטי הסבה",
    };
    const reportSideViewEntity = {
      component: ReportEntity,
      preview: true,
      readOnly: true,
      title: "פרטי דוח",
      recordUrl: "reports",
    };
    const excelExport = [
      {
        title: "יצוא טבלה",
        action: this.export,
      },
      {
        title: "יצוא מקובץ לפי עיריות",
        action: this.exportByMunic,
      },
      {
        title: "יצוא מקובץ לפי לקוח",
        action: this.exportByClient,
      },
    ];
    const fields = [
      { ...reportsFields.Address, checked: false },
      { ...reportsFields.Alerts, checked: false },
      { ...reportsFields.ApprovalDate, checked: false },
      { ...reportsFields.ClientComment, checked: false },
      { ...reportsFields.CreateDate, checked: false },
      { ...reportsFields.CurrentAmount, checked: true },
      { ...reportsFields.DateTaken, checked: true },
      { ...reportsFields.Driver, checked: false },
      { ...reportsFields.DriverAmount, checked: false },
      { ...reportsFields.HasScreenshot, checked: false },
      { ...reportsFields.IsPaid, checked: false },
      { ...reportsFields.IsPaidByDriver, checked: false },
      { ...reportsFields.LastPayDate, checked: true },
      { ...reportsFields.ManagerComment, checked: false },
      { ...reportsFields.MuniIdNumber, checked: false },
      { ...reportsFields.MuniReferenceNumber, checked: false },
      { ...reportsFields.Municipality, checked: true },
      { ...reportsFields.OriginalAmount, checked: true },
      { ...reportsFields.TransferredFromName, checked: true },
      { ...reportsFields.PartialPaidAmount, checked: false },
      { ...reportsFields.PaymentDate, checked: false },
      { ...reportsFields.PaymentReferenceNumber, checked: false },
      { ...reportsFields.PaymentUrl, checked: true },
      { ...reportsFields.PlateNumber, checked: true },
      { ...reportsFields.Profile, checked: true },
      { ...reportsFields.Reason, checked: false },
      { ...reportsFields.RelevantLaw, checked: false },
      { ...reportsFields.ReportCause, checked: false },
      { ...reportsFields.ReportNumber, checked: true },
      { ...reportsFields.Status, checked: true },
      { ...reportsFields.TransferCompleteDate, checked: true },
      { ...reportsFields.TransferError, checked: true },
      { ...reportsFields.TransferPdfUrl, checked: true },
      { ...reportsFields.TransferSignedDate, checked: true },
      { ...reportsFields.TransferStatus, checked: true },
      { ...reportsFields.TransferredDate, checked: true },
      { ...reportsFields.VehicleType, checked: true },
      { ...reportsFields.VerificationDate, checked: false },
    ];

    this.tabs = [
      {
        id: "TransfersCompanyMissingDocumentReports",
        title: "מסמכים חסרים להסבה",
        isPrimary: true,
        getDataAsync: this.getMissingDocumentReports,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          { ...reportsfilters.Profile },
          { ...reportsfilters.Driver },
          { ...reportsfilters.TransferStatusOnlyNotTransferredAndRejected },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.Municipality },
          { ...reportsfilters.Other },
          { ...reportsfilters.DateTypes },
        ],
        sortField: "LastPayDate",
        sortOrder: "descfalse",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity: reportSideViewEntity,
        excelExport,
        // tableActions: [TableActions.createTransfersCompany, TableActions.addTask],
        tableActions: (() => {
          let actions = [
            TableActions.createTransfersCompany,
            TableActions.addTask,
          ];
          if (this.isAdmin && !this.isSosnaPortal) {
            actions = actions.concat([
              {
                ...TableActions.reportsAlertsGenerator,
                Label: "סריקת התראות",
              },
            ]);
          }
          return actions;
        })(),
      },
      {
        id: "TransfersCompanySignByMissingContracts",
        title: "חוזים חסרים",
        getDataAsync: this.getMissingContracts,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          // { ...reportsfilters.NotReportStatus },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          { ...reportsfilters.Profile },
          { ...reportsfilters.Driver },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.MuniIdNumber },
          {
            ...reportsfilters.TransferStatusWNotTransferred,
            selected: [
              0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            ],
          },
          { ...reportsfilters.Municipality },
          { ...reportsfilters.Other },
          { ...reportsfilters.DateTypes },
        ],
        sortField: "Id",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        excelExport,
        sideViewEntity: reportSideViewEntity,
        tableActions: (() => {
          let actions = [
            TableActions.addTask
          ];
          if (this.isAdmin) {
            actions = actions.concat([
              TableActions.changeTransferStatusCompany,
              {
                ...TableActions.reportsAlertsGenerator,
                Label: "סריקת התראות",
              },
            ]);
          }
          return actions;
        })(),
      },
      {
        id: "TransfersCompanySignByLawyerPending",
        title: 'ממתינים לחתימת עו"ד',
        getDataAsync: this.getTransfersViaLawyer,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.NotReportStatus, selected: [0] },
          { ...reportsfilters.TransferStatus, selected: [12] },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.Municipality },
        ],
        sortField: "Id",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity,
        tableActions: [TableActions.signOnReports, TableActions.addTask],
      },
      {
        id: "TransfersCompanyViaMail",
        title: "ממתינים לשליחה בדואר",
        getDataAsync: this.getTransfersViaMail,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus, selected: [0] },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          { ...reportsfilters.Profile },
          { ...reportsfilters.Driver },
          { ...reportsfilters.TransferStatus, selected: [4, 11] },
          { ...reportsfilters.ReportNumber },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.Municipality },
          { ...reportsfilters.DateTypes },
        ],
        sortField: "DateTaken",
        sortOrder: "desc",
        checkableRows: true,
        excelExport,
        fastSearch: true,
        sideViewEntity,
        tableActions: (() => {
          let actions = [
            TableActions.postRegisteredMail,
            TableActions.printTransfers,
            TableActions.markAsPaid,
            TableActions.addTask
          ];
          if (this.isAdmin) {
            actions = actions.concat([
              TableActions.changeTransferStatusCompany,
            ]);
          }
          return actions;
        })(),
      },
      {
        id: "TransfersCompanyHandling",
        title: "הסבות לטיפול",
        getDataAsync: this.getReportsForHandle,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus, selected: [0] },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          { ...reportsfilters.Profile },
          { ...reportsfilters.Driver },
          { ...reportsfilters.TransferStatus, selected: [6, 14, 17] },
          { ...reportsfilters.ReportNumber },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.Municipality },
          { ...reportsfilters.DateTypes },
        ],
        sortField: "Id",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity,
        excelExport,
        tableActions: [
          TableActions.postRegisteredMail,
          TableActions.printTransfers,
          TableActions.markAsPaid,
          TableActions.addTask,
          {
            ...TableActions.changeTransferStatusCompany,
            Label: "שינוי סטטוס הסבת חברה",
          },
        ],
      },
    ];
    if (this.isAdmin) {
      this.tabs.push({
        id: "TransfersClarificationStatus",
        title: "הסבות לבירור סטטוס",
        getDataAsync: this.getReportsForClarification,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus, selected: [0] },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          { ...reportsfilters.Profile },
          { ...reportsfilters.Driver },
          { ...reportsfilters.ReportNumber },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.Municipality },
          {
            ...reportsfilters.TransferStatus,
            selected: [3, 4, 5, 7, 8, 15, 19],
          },
          {
            ...reportsfilters.DateTypes,
            Options: [
              {
                ...FilterService.DateSearchTypes.TransferredDate,
                preSettedDate: {
                  value: 9,
                  endDate: this.monthAdd(new Date(), -1),
                },
              },
            ],
          },
        ],
        sortField: "Id",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity,
        excelExport,
        tableActions: (() => {
          let actions = [
            TableActions.postRegisteredMail,
            TableActions.printTransfers,
            TableActions.markAsPaid,
            TableActions.changeTransferStatusCompany,
            TableActions.addTask,
          ];
          if (this.isAdmin && !this.isSosnaPortal) {
            actions = actions.concat([
              {
                ...TableActions.reportsDetailsScanning,
                Label: "סריקת נתוני דוחות",
              },
            ]);
          }
          return actions;
        })(),
      });
    }

    this.setProfileIdInTab(this.profileId);
  },
  methods: {
    setProfileIdInTab(val) {
      if (val) {
        const currTab = this.tabs.find(
          (tab) => tab.id === "TransfersCompanySignByMissingContracts"
        );
        const currFilter = currTab.filters.find((f) => f.Query === "ProfileIds");
        currFilter.selected = [val];
        // currTab.getDataAsync(currTab);
        currTab.getDataAsync({
          PageSize: currTab.perPage,
          PageNumber: currTab.page || 1,
          SortedBy: currTab.sortField,
          IsAscending: currTab.sortOrder !== "desc",
          cancelToken: currTab.cancelTokenSource,
        });
      }
    },
    monthAdd(date, month) {
      let temp = date;
      temp = new Date(date.getFullYear(), date.getMonth(), 1);
      temp.setMonth(temp.getMonth() + (month + 1));
      temp.setDate(temp.getDate() - 1);
      if (date.getDate() < temp.getDate()) {
        temp.setDate(date.getDate());
      }
      return temp;
    },
    getTransfersViaMail({ cancelToken, setPermanentQuery, ...rest }) {
      const query = {
        IgnoreDoubleFineForTransfer: true,
        IsPaid: false,
      };
      setPermanentQuery(query);
      return ReportService.search(
        {
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },
    getAllReports({ cancelToken, ...rest }) {
      return ReportService.search(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getMissingContracts({ cancelToken, setPermanentQuery, ...rest }) {
      const query = {
        IsPaid: false,
      };
      setPermanentQuery(query);

      return ReportService.search(
        {
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },
    getTransfersViaLawyer({ cancelToken, setPermanentQuery, ...rest }) {
      const query = {
        IsPaid: false,
      };
      setPermanentQuery(query);

      return ReportService.search(
        {
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },
    getMissingDocumentReports({ cancelToken, setPermanentQuery, ...rest }) {
      const query = {
        CanBeTransferred: true,
        IgnoreDoubleFineForTransfer: true,
        IgnoreExceptionsForTransfer: true,
        IsNotMine: true,
        IsPaid: false,
      };

      setPermanentQuery(query);

      return ReportService.search(
        {
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },
    getReportsForHandle({ cancelToken, setPermanentQuery, ...rest }) {
      const query = {
        IgnoreDoubleFineForTransfer: true,
        IsPaid: false,
      };

      setPermanentQuery(query);
      return ReportService.search(
        {
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },
    getReportsForClarification({ cancelToken, setPermanentQuery, ...rest }) {
      const query = rest.ToTransferredDate
        ? { IsPaid: false }
        : {
          IsPaid: false,
          ToTransferredDate: this.monthAdd(new Date(), -1),
        };
      setPermanentQuery(query);
      return ReportService.search(
        {
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },

    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    export({ loading, query }) {
      loading(true);
      ReportService.exportXsl(query)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות הסבה לחברה ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportByMunic({ loading, query }) {
      loading(true);
      ReportService.exportXsl(query, "Municipalities")
        .then((r) => {
          this.saveFile(
            r.data,
            "יצוא דוחות הסבה לחברה ממערכת Betterway מקובץ לפי עיריות"
          );
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      ReportService.exportXsl(query, "Profiles")
        .then((r) => {
          this.saveFile(
            r.data,
            "יצוא דוחות הסבה לחברה ממערכת Betterway מקובץ לפי לקוחות"
          );
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
  },
  watch: {
    profileId(newValue) {
      this.setProfileIdInTab(newValue);
    },
  },
};
</script>
